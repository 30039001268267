const ready = () => {

	function languageWarn(e) {
		e.preventDefault();
		let dialog = document.querySelector('.dialog');
		let url = e.target.href;
		if (dialog !== null) {
			dialog.querySelector('.dialog__button').href = url;
			dialog.showModal();
		} else {
			window.location = url;
		}
	};
	
	function tableScreen() {
		let tables = document.querySelectorAll('table');
		tables.forEach(table => {
			if (table.scrollWidth > table.parentNode.clientWidth) {
				table.parentNode.parentNode.classList.add('sticky');
			} else {
				table.parentNode.parentNode.classList.remove('sticky');
			}
		});
	};
	
	function toggleVideo(e) {
		let target = e.target;
		if (target.classList.contains('global-footer__toggle--is-playing')) {
			target.classList.remove('global-footer__toggle--is-playing');
			target.setAttribute('aria-label', 'Play video');
			target.setAttribute('aria-pressed', 'true');
			document.querySelector('.global-footer__video').pause();
		} else {
			target.classList.add('global-footer__toggle--is-playing');
			target.setAttribute('aria-label', 'Pause video');
			target.setAttribute('aria-pressed', 'false');
			document.querySelector('.global-footer__video').play();
		}
	};

	// Initialize
	document.querySelectorAll('.ctas .button').forEach( function(elem) { elem.addEventListener('click', languageWarn, 1); });
	
	document.querySelector('.global-footer__toggle').addEventListener('click', toggleVideo);
	if (document.querySelector('table')) {
 		window.addEventListener('resize', tableScreen);
		tableScreen();
	}
};

window.addEventListener('DOMContentLoaded', ready, false);
